<!--运行维护-红码核实-->
<template>
  <section>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-cascader
              placeholder="所属单位"
              @change="getData"
              v-model="filter.Owner"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable/>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="getData"
              v-model="filter.MaintainerId"
              filterable
              clearable
              placeholder='运维人员'
            >
              <el-option
                v-for="item in MaintainerList"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >导出
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="datalist.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          :max-height="clientHeight"
          style="width: 100%"
        >
          <el-table-column type="index" label="#" align="center" width="55"/>
          <el-table-column
            prop="LocaleName"
            label="监测点"
            width="160"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="MN"
            label="设备编号"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="CompliantDays"
            label="达标天数"
            width="100"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.CompliantDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ExcessDays"
            label="超标天数"
            width="100"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.ExcessDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FilterAbnormallyUsedDays"
            label="不正常使用净化器天数"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.FilterAbnormallyUsedDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="OfflineCausedByShopDays"
            label="店家原因离线天数"
            sortable="custom"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.OfflineCausedByShopDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="CreateAt"
            :formatter="dateFormatter"
            label="派单日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="MaintainerName"
            label="运维人员"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="VerifyAt"
            :formatter="dateFormatter"
            label="核实日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Status"
            label="状态"
            align="center"
            width="80"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.Status === 'WAIT_TO_VERIFY'">待核实</el-tag>
              <el-tag type="warning" v-else-if="scope.row.Status === 'WAIT_TO_HANDLE'">待处理</el-tag>
              <el-tag type="warning" v-else-if="scope.row.Status === 'WAIT_TO_CHECK'">待审核</el-tag>
              <el-tag type="success" v-else-if="scope.row.Status === 'FINISHED'">已完成</el-tag>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            min-width="120"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text" size="mini"
                :disabled="scope.row.Status === 'WAIT_TO_VERIFY'"
                @click="handleDialogOpen(scope.row,'查看', false)"
              >查看
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="审核"
            align="center"
            fixed="right"
            min-width="120"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="mini"
                         :disabled="scope.row.Status !== 'WAIT_TO_CHECK'"
                         @click="handleVerify(scope.row)">审核
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 工具条 -->
    <div class="toolbar" style="text-align:center">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      ></el-pagination>
    </div>
    <!--处理界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handleClose"
      width="550px"
    >
      <el-form
        :model="formData"
        :rules="formRule"
        ref="form"
        size="mini"
        label-width="108px"
        label-position="left"
        :disabled="dlg.isView"
      >
        <el-form-item prop="LocaleName" label="监测点名称">
          <el-input
            v-model="formData.LocaleName"
            disabled
            placeholder="无"
          />
        </el-form-item>
        <el-form-item prop="Handler" label="处理人员">
          <el-input
            v-model="formData.Handler"
            disabled
            placeholder="无"
          />
        </el-form-item>
        <el-form-item prop="VerifyAt" label="核实日期">
          <el-date-picker
            disabled
            placeholder="核实日期"
            type="date"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="formData.VerifyAt"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          prop="Cause"
          label="核实详情"
        >
          <el-input
            type="textarea"
            autosize
            v-model.trim="formData.Cause"
          />
        </el-form-item>
        <el-form-item
          prop="Status"
          label="是否属实">
          <el-select v-model="formData.Status">
            <el-option label="属实" value="WAIT_TO_HANDLE"/>
            <el-option label="不属实" value="WAIT_TO_CHECK"/>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="Status"
          label="获取定位">
          <baidu-map
            class="bm-view"
            :center="mapCenter"
            :zoom="mapZoom"
            ak="770b4c07458f53896ff0abd948755e20"
            @ready="handleMapReady"
            v-if="dlg.isDeal"
          >
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
              @locationSuccess="getPosition"
            ></bm-geolocation>
          </baidu-map>
          <span>{{ formData.Addr ? formData.Addr : '暂无定位' }}</span>
        </el-form-item>
        <el-form-item
          prop="MaintenancePicS"
          label="处理照片">
          <div v-if="dlg.isView">
            <el-image
              class="view-img"
              v-for="(item, i) in fileList"
              :key="i"
              :src="item"
              :preview-src-list="fileList"
            >
              <div slot="error" class="image-slot">
                暂无图片
              </div>
            </el-image>
          </div>
          <el-upload
            ref="upload"
            accept=".jpg, .jpeg, .png"
            list-type="picture-card"
            :action="upload()"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            multiple
            v-if="dlg.isDeal"
          >
            <i class="el-icon-plus"/>
            <div slot="tip">上传照片（支持jpg、jpeg、png）</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="dlg.isDeal">
        <el-button type="primary" size="mini" @click.native="handleSubmit(true)">提交</el-button>
        <el-button type="primary" size="mini" @click="dlg.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="红码核实单审核"
      :visible.sync="verifyVisible"
      width="30%"
      center>
      <el-form>
        <el-form-item label="审核备注：">
          <el-input
            placeholder="请输入审核备注"
            v-model="verifyRemark"/>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="handleVerifyStatus('FINISHED')">通  过</el-button>
        <el-button type="danger" @click="handleVerifyStatus('WAIT_TO_VERIFY')">不通过</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  dateFormater,
  getUserInfo,
  export_json, ownerJoin
} from '@/util'
import conf from '@/config'
import BmGeolocation from "vue-baidu-map/components/controls/Geolocation.vue";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";

export default {
  name: 'HealthCodeRecordVerification',
  components: {BaiduMap, BmGeolocation},
  data() {
    return {
      conf,
      changeState: false,
      exportLoading: false,
      loading: false,
      datalist: {},
      userInfo: null,
      dlg: {
        dialogSubmitted: false,
        visible: false,
        isDeal: false,
        isView: false,
        title: ''
      },
      filter: {
        name: null,
        field: 'name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      MaintainerList: [],
      fileList: [],
      formData: {},
      formRule: {
        Cause: [{required: true, message: '不可为空'}],
        Status: [{required: true, message: '不可为空'}],
        Addr: [{required: true, message: '不可为空'}],
        MaintenancePicS: [{required: true, message: '不可为空'}],
      },
      verifyVisible: false,
      verifyId: '',
      verifyRemark: '',
      mapCenter: {lng: 120.306305, lat: 30.424877},
      mapZoom: 14,
    }
  },
  computed: {
    ...mapState({
      customerProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['props', 'clientHeight', 'customerTree', 'locale', 'user'])
  },
  created() {
    this.userInfo = getUserInfo().Info
    this.getData()
  },
  methods: {
    statusFormatter(r, c) {
      switch (r[c.property]) {
        case 'WAIT_TO_VERIFY':
          return '待核实'
        case 'WAIT_TO_HANDLE':
          return '待处理'
        case 'FINISHED':
          return '已完成'
        default:
          return '-'
      }
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    dateFormatter(r, c) {
      return dateFormater(r[c.property], false, false)
    },
    getData: function () {
      this.loading = true
      const payload = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        FormType: 1,
        Param: {}
      }
      if (this.filter.Owner && this.filter.Owner.length > 0) {
        const owner = `${ownerJoin(this.filter.Owner, this.userInfo)}`
        payload.Param['health_code_maintenance.owner'] = {S: 4, V: owner}
      }
      payload.Param['health_code_maintenance.status'] = {S: 5, V: ['WAIT_TO_CHECK']}
      if (this.filter.MaintainerId) {
        payload.Param['maintainer_id'] = {S: 2, V: this.filter.MaintainerId.toString()}
      }
      this.$post('admin/listHealthCodeRecord', payload).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.getData()
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleDialogOpen(row, title, canEdit) {
      this.dlg.title = title
      this.dlg.visible = true
      this.dlg.dialogSubmitted = false
      canEdit ? this.dlg.isDeal = true : this.dlg.isView = true
      this.fileList = Object.assign({}, row.MaintenancePicS)
      this.formData = {
        Id: row.Id,
        LocaleName: row.LocaleName,
        Handler: row.Handler,
        MaintenancePicS: row.MaintenancePicS,
        Status: row.Status === 'WAIT_TO_VERIFY' ? '' : row.Status,
        Addr: row.Addr,
        VerifyAt: this.formData.hasOwnProperty('VerifyAt') ? this.formData.VerifyAt : new Date(new Date().getTime())
      }
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              // eslint-disable-next-line prefer-const
              let {Id, Cause, Addr, MaintenancePicS, Status} = this.formData
              this.$post('admin/updateHealthCodeRecord', {Id, Cause, Addr, MaintenancePicS, Status})
                .then(() => {
                  this.dlg.visible = false
                })
            })
            .finally(() => {
              this.dlg.dialogSubmitted = true // 当提交后更改为true，让@close刷新列表
            })
        }
      })
    },
    handleClose() {
      if (this.dlg.dialogSubmitted) {
        this.handleRefresh()
      }
      this.dlg.isView = false
      this.dlg.isDeal = false
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const para = {
        StartAt: 0 | (this.filter.page - 1) * this.filter.size / 1000,
        Size: exportSize,
        FormType: 1,
        IsDownload: true,
        Param: {}
      }
      if (this.filter.Owner && this.filter.Owner.length > 0) {
        const owner = `${ownerJoin(this.filter.Owner, this.userInfo)}`
        para.Param['health_code_maintenance.owner'] = {S: 4, V: owner}
      }
      if (this.filter.Status) {
        para.Param['health_code_maintenance.status'] = {S: 5, V: [this.filter.Status]}
      }
      if (this.filter.MaintainerId) {
        para.Param['maintainer_id'] = {S: 2, V: this.filter.MaintainerId.toString()}
      }
      this.$post('admin/listHealthCodeRecord', para).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    },
    handleVerify(row) {
      this.verifyRemark = ''
      this.verifyVisible = true
      this.verifyId = row.Id
    },
    /**
     * 处理审核通过与否
     * @param status
     */
    handleVerifyStatus(status) {
      this.$post('admin/updateHealthCodeRecord', {Id: this.verifyId, Status: status, Cause: this.verifyRemark})
        .then(() => {
          this.verifyVisible = false
          this.getData()
        })
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.formData.MaintenancePicS.push(res.Data)
    },
    handleRemove(file, fileList) {
      const i = this.formData.MaintenancePicS.indexOf(file.response.Data)
      this.formData.MaintenancePicS.splice(i, 1)
    },
    handleMapReady() {
      if (this.formData.Lng && this.formData.Lat) {
        this.mapCenter = {lng: this.formData.Lng, lat: this.formData.Lat}
      }
    },
    getPosition(pos) {
      this.formData.Lng = pos.point.lng.toString()
      this.formData.Lat = pos.point.lat.toString()
      this.formData.Addr = pos.addressComponent['province'] + pos.addressComponent['city'] +
        pos.addressComponent['district'] + pos.addressComponent['street'] +
        pos.addressComponent['streetNumber']
    },
  }
}
</script>

<style scoped>
.bm-view {
  width: 100%;
  height: 150px;
}
</style>
